@import './constants';

#unauth-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .unauth-logo-container {
    background-repeat: no-repeat;
    background-color: $White;
    box-shadow: 0px 8px 24px rgba(10, 27, 38, 0.08);
    width:100%;
    height: 32px;
    margin-bottom: 40px;
    padding:19px 0;

    .unauth-logo{
      width:180px;
      height:33px;
      background-image: url('../images/rr-logo.png');
      background-size: cover;
      margin-left: 150px;

    }
   
  }

  

  .unauth-container {
    padding: 42px 90px 45px;
    min-height: 300px;
    min-width: 409px;

    &#email-container {
      padding: 60px;
      min-width: 430px;
      min-height: unset;
    }

    .unauth-header {
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .unauth-subheader {
      font-size: 14px;
      font-weight: 400;
      color: $FiordBlue;
      margin-bottom: 28px;
      text-align: center;
    }

    #unauth-reset-password-form {
      .password-triangle,
      .confirm-password-triangle {
        display: none;
        &.active {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-top: 11px solid transparent;
          border-bottom: 11px solid transparent;
          border-left: 10px solid $GeyserGray;

          .inner-triangle {
            position: relative;
            left: -10px;
            top: -9px;
            width: 0;
            height: 0;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 9px solid $White;
          }
        }
      }

      .password-triangle {
        &.active {
          left: 75px;
          top: 158px;
        }
      }

      .confirm-password-triangle {
        &.active {
          left: 75px;
          top: 240px;
        }
      }

      .unauth-password-validation,
      .unauth-password-confirmation-validation {
        display: none;
        position: absolute;
        width: 374px;
        padding: 24px 30px 30px;
        border-radius: 6px;
        border: 1px solid rgba(87, 117, 146, 0.25);
        background: $White;

        &.active {
          display: block;
        }

        .unauth-validation-checks {
          .validation-item {
            display: flex;
            align-items: center;
            padding-bottom: 10px;

            &:last-of-type {
              padding-bottom: 0;
            }

            svg {
              height: 20px;
              width: 20px;
              padding-right: 10px;

              &.satisfied {
                fill: $JungleGreen;
              }

              &.unsatisfied {
                fill: $GeyserGray;
              }
            }
            p {
              display: inline;
              font-size: 12px;
              font-weight: 600;
              color: $NileBlue;
              line-height: 22px;
              margin: 0;
            }
          }
        }
      }

      .unauth-password-validation {
        top: 138px;
        left: -360px;

        ul {
          padding-left: 18px;
        }

        li {
          font-size: 12px;
          font-weight: 400;
          color: $FiordBlue;
          line-height: 22px;

          &::marker {
            font-size: 80%;
            font-weight: bold;
          }
        }

        hr {
          border: 0.5px solid $GeyserGray;
          margin-top: 24px;
          margin-bottom: 24px;
        }

        .composition {
          padding-bottom: 16px;
        }
      }

      .unauth-password-confirmation-validation {
        width: 175px;
        padding: 12px;
        top: 226px;
        left: -125px;
      }
    }

    .form-group {
      margin-top: 20px;
      &:first-of-type {
        margin-top: 0;
      }
      .form-label {
        color: $FiordBlue;
        font-size: 12px;
        font-weight: 400;
      }
      .form-input {
        background: $White;
        width: 327px;
        border: none;
        border-bottom: 1px solid $GeyserGray;
      }
    }

    .unauth-submit {
      width: 164px;
      margin-top: 32px;
      height: 44px;
      margin: auto;

      &.forgot-password {
        width: 245px;
      }
    }
  }

  .unauth-links,
  .unauth-email-edit {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    a {
      text-decoration: none;
      color: $RoyalBlue;
    }
  }

  .unauth-links {
    padding-top: 32px;
  }

  .unauth-email-edit {
    padding-top: 0;

    p {
      margin-top: 12px;
    }

    a {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
