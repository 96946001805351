@import '../assets/styles/constants';

.intro-modal {
  width: 512px;
  height: 383px;
  font-family: Open Sans, sans-serif;

  .intro-modal-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 44px;
    width: 100%;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.04em;
      color: $NileBlue;
      width: 100%;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $FiordBlue;
    }

    .intro-modal-button-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;

      button {
        padding: 12px 24px;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
      }

      button:first-of-type {
        margin-right: 20px;
        background: $White;
        color: $RoyalBlue;
        border: 1px solid $GeyserGray;
      }

      button:last-of-type {
        background: $RoyalBlue;
        color: $White;
        border: 1px solid $RoyalBlue;

        &:hover {
          background: #1056b2;
          border: 1px solid #1056b2;
        }
      }
    }
  }
}
